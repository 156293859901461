import Enums from "../model/enums";
import {format} from "date-fns";
import {getConditionalWagesFieldName} from "@/util/seYearSpecific";
import SeIncomeType from "@/pages/Dashboard/Components/SeIncomeType.vue";

const formatXml = (xml) => {
    const PADDING = ' '.repeat(2); // set desired indent size here
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;

    xml = xml.replace(reg, '$1\r\n$2$3');

    return xml.split('\r\n').map((node, index) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
            indent = 0;
        } else if (node.match(/^<\/\w/) && pad > 0) {
            pad -= 1;
        } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
            indent = 1;
        } else {
            indent = 0;
        }

        pad += indent;

        return PADDING.repeat(pad - indent) + node;
    }).join('\r\n');
}

export const getOldestDate = (string1, string2) => {
  let oldestDate = null
  if (string1 && string2) {
    let dateString1 = new Date(string1)
    let dateString2 = new Date(string2)

    if (dateString2.getTime() < dateString1.getTime()) {
      oldestDate = dateString2
    } else {
      oldestDate = dateString1
    }
  } else if (string1) {
    oldestDate = new Date(string1)
  } else if (string2) {
    oldestDate = new Date(string2)
  }
  return oldestDate
}

export const toEnum = (enumType, id) => {
  let enumOptional = Object.entries(enumType).find( ([k,v]) => v.id === id);
  if (enumOptional && enumOptional.length > 1) {
    return enumOptional[1]
  }
  return undefined
}

export const hasNumericValue = (fields, name) => {
  if (!fields || !Array.isArray(fields)) return false

  let hasValue = false
  let field = fields.find(f => f.id === name)
  if (field && !field.isEmpty) {
    if (field.value) {
      let num = Number(field.value)
      if (!Number.isNaN(num) && (num > 0 || num < 0)) {
        hasValue = true
      }
    }
  }
  return hasValue
}

export const hasPositiveNumericValue = (fields, name) => {
  if (!fields || !Array.isArray(fields)) return false

  let hasValue = false
  let field = fields.find(f => f.id === name)
  if (field && !field.isEmpty) {
    if (field.value) {
      let num = Number(field.value)
      if (!Number.isNaN(num) && (num > 0)) {
        hasValue = true
      }
    }
  }
  return hasValue
}

export const hasZeroOrNullValue = (fields, name) => {
  if (!fields || !Array.isArray(fields)) return true

  let hasNoValue = true
  let field = fields.find(f => f.id === name)
  if (field && !field.isEmpty) {
    if (field.value) {
      let num = Number(field.value)
      if (!Number.isNaN(num) && (num > 0 || num < 0)) {
        hasNoValue = false
      }
    }
  }
  return hasNoValue
}

export const getTaxFormFieldValue = (taxData, year, formId, fieldName) => {
  return getTaxFormFields(taxData, year, formId)?.find(field => field.id === fieldName)?.value
}

export const getTaxFormField = (taxData, year, formId, fieldName) => {
  return getTaxFormFields(taxData, year, formId)?.find(field => field.id === fieldName)
}

export const getTaxFormFields = (taxData, year, formId) => {
  if (!taxData) return undefined
  return taxData.find(f => f.year === year && f.formId === formId)?.fields
}

export const getFieldValue = (fields, name) => {
  if (!fields || !Array.isArray(fields)) return undefined
  return fields.find(f => f.id === name)?.value
}

export const getFieldValueAsNumberZeroed = (fields, name) => {
  if (!fields || !Array.isArray(fields)) return 0

  let field = fields.find(f => f.id === name)
  if (field && !field.isEmpty) {
    if (field.value) {
      let num = Number(field.value)
      if (!Number.isNaN(num)) {
        return num
      }
    }
  }
  return 0
}

export const isValidDate = (s) => {
  // Assumes s is "mm/dd/yyyy"
  if ( ! /^\d\d\/\d\d\/\d\d\d\d$/.test(s) ) {
    return false;
  }
  const parts = s.split('/').map((p) => parseInt(p, 10));
  parts[0] -= 1;
  const d = new Date(parts[2], parts[0], parts[1]);
  return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
}

export const toMMDDYYYYDateString = (dateString) => {
  if (dateString.includes("-")) {
    let parts = dateString.split('-')
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0: January - 0, February - 1, etc.
    if (parts[0] && parts[1] && parts[2]) {
      return format(new Date(parts[0], parts[1] - 1, parts[2]), "MM/dd/yyyy")
    }
  } else if (dateString.includes("/")) {
    return dateString
  }
  return null
}


export const processLoadedRequest = (data) => {
  let isNewCreatedDate = !data.createdDate
  data.createdDate = data.createdDate ? new Date(data.createdDate) : new Date()

  if (!data.extraData) {
    data.extraData = {
      businessStructureProvided: data.businessStructure
    }
    if (data.employmentStartDate) {
      data.extraData.employmentStartDateProvided = toMMDDYYYYDateString(data.employmentStartDate)
    }
  }

  //determine tax years
  let ipy1 = data.createdDate.getFullYear() - 1
  let ipy2 = ipy1 - 1

  // derive lastYearTaxReturnFiled
  if (data.extraData.lastYearTaxReturnFiled == null || isNewCreatedDate) {
    if (data.taxData) {
      data.extraData.lastYearTaxReturnFiled = data.taxData.some(td => td.year === ipy1.toString())
    } else {
      data.extraData.lastYearTaxReturnFiled = true
    }
  }

  if (!data.extraData.lastYearTaxReturnFiled) {
    ipy1--
    ipy2--
  }
  let py1 = ipy1.toString()
  let py2 = ipy2.toString()

  //derive selectedTaxForms
  if (!data.selectedTaxForms) {
    let stfPy1 = {"year": py1, formIds: []}
    let stfPy2 = {"year": py2, formIds: []}

    if (data.taxData) {
      let busStructure = toEnum(Enums.SeBusinessStructureType, data.extraData.businessStructureProvided)
      const requestFormsOfInterest = data.taxData.filter(td => (td.year === py1 || td.year === py2) && (td.fields?.length > 0))

      const stfs = [stfPy1, stfPy2]
      stfs.forEach(stf => {
        [Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tf1040].forEach(indTaxRet => {
          if (requestFormsOfInterest.some(f => f.year === stf.year && f.formId === indTaxRet)) {
            stf.formIds = [indTaxRet]
          }
        })
        //if nothing selected, use 1040 for py1 by default
        if (stf.formIds.length === 0 && stf.year === stfPy1.year) {
          stf.formIds.push(Enums.SeTaxFormType.tf1040)
        }

        let busTaxReturn
        if (busStructure === Enums.SeBusinessStructureType.sCorp) {
          busTaxReturn = Enums.SeTaxFormType.tf1120s
        } else if (busStructure === Enums.SeBusinessStructureType.partnership) {
          busTaxReturn = Enums.SeTaxFormType.tf1065
        } else if (busStructure === Enums.SeBusinessStructureType.corp) {
          busTaxReturn = Enums.SeTaxFormType.tf1120
        }
        if (busTaxReturn) {
          if (requestFormsOfInterest.some(f => f.year === stf.year && f.formId === busTaxReturn)) {
            if (stf.formIds.length === 0) {
              stf.formIds.push(Enums.SeTaxFormType.tf1040)
            }
            stf.formIds.push(busTaxReturn)
          }
        }
        //if only 1040 is selected also add 1040c
        if (stf.formIds.length === 1 && stf.formIds.some(f => [Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tf1040].includes(f))) {
          if (data.incomeType === Enums.SeIncomeTypeType.rental) {
            stf.formIds.push(Enums.SeTaxFormType.tf1040se)
          } else {
            stf.formIds.push(Enums.SeTaxFormType.tf1040c)
          }
        }
      })
    }
    data.selectedTaxForms = [stfPy1, stfPy2]
  }
  return data
}

export const hasPositive1040Wages = (taxData, year) => {
  let fields1040 = taxData.find(form => form.year === year && form.formId === Enums.SeTaxFormType.tf1040)?.fields
  let fields1040sr = taxData.find(form => form.year === year && form.formId === Enums.SeTaxFormType.tf1040sr)?.fields
  let fields1040nr = taxData.find(form => form.year === year && form.formId === Enums.SeTaxFormType.tf1040nr)?.fields
  return hasPositiveNumericValue(fields1040, getConditionalWagesFieldName(year)) || hasPositiveNumericValue(fields1040sr, getConditionalWagesFieldName(year)) || hasPositiveNumericValue(fields1040nr, getConditionalWagesFieldName(year));
}

export default { formatXml, getOldestDate, toEnum, hasNumericValue, hasPositiveNumericValue,
  hasZeroOrNullValue, getFieldValue, getTaxFormFieldValue, getTaxFormField, getTaxFormFields, getFieldValueAsNumberZeroed,
  toMMDDYYYYDateString, isValidDate, processLoadedRequest, hasPositive1040Wages }
