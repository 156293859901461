import {mapGetters, mapState} from "vuex";
import Enums from "../model/enums";
import {SET_TAX_FORM_DATA} from "../store/actions/se";
import baseFormMixin from "./BaseFormMixin";

export const taxMultiPartFormMixin = {
  mixins: [baseFormMixin],
  data() {
    return {
      taxFormPart: "1",
    }
  },
  validations: {
    tableData: {
      $each: {
        checkHasAnswer(row) {
          return this.checkHasAnswer(row)
        },
        checkRequired(row) {
          return this.checkRequired(row)
        }
      }
    },
    allAnswered: {
      answered: (v) => v
    }
  },
  created() {
    this.Enums = Enums
    this.init(this.$route.params.year, this.$route.params.formName, this.$route.params.part);
  },
  beforeRouteUpdate(to, from, next) {
    this.init(to.params.year, this.$route.params.formName, to.params.part);
    this.$v.$reset()
    next();
  },
  computed: {
    pdfViewerKey() {
      return this.taxFormYear + this.taxFormPart
    },
    allAnswered() {
      return this.skippedForm
        || this.isLocked
        || !this.tableData.some((f, index) => f.required && !f.value && !f.isEmpty && !this.isSkipped[index])
    },
    isEmpty() {
      return this.tableData.map(f => f.isEmpty);
    },
  },

  watch: {
  },

  methods: {
    init(year, formName, part) {
      this.taxFormYear = year;
      this.taxFormPart = part;
      this.taxFormId = formName;

      const form = this.seConf.taxYears.filter(ty => ty.year === this.taxFormYear)
        .flatMap(rtn => rtn.forms)
        .flatMap(form => form.forms)
        .find(f => f.id === this.taxFormId)
        .parts.find(p => p.part === this.taxFormPart)

      this.seFields = form.fields
      this.pdf = form.pdf
      this.tableData = []
      this.skippedForm = false

      let storedData = this.seRequest.taxData.find(f => f.year === this.taxFormYear && f.formId === this.taxFormId);
      if (storedData) {
        this.skippedForm = storedData.skipped
        if (storedData.fields) {
          storedData = storedData.fields;
        }
      }

      //initial fill
      this.seFields.forEach(f => {
        let existingRow = this.tableData.find(td => td.id === f.id)
        if (existingRow) {
          existingRow.fieldHighlights.push(f.fieldName)
        }
        else if (!existingRow && !f.staticText) {
          let row = {
            id: f.id,
            line: f.line,
            name: f.label,
            value: f.defaultValue ? f.defaultValue: '',
            isEmpty: this.isEmptyByDefault(f),
            fieldName: f.fieldName,
            required: f.required,
            isCurrency: f.isCurrency,
            isPositive: f.isPositive,
            isBoolean: f.isBoolean,
            isInteger: f.isInteger,
            hasHelp: f.hasHelp,
            showHelp: false,
            fieldHighlights: f.fieldHighlights ? f.fieldHighlights : [f.fieldName],
            mask: this.getFieldMask(f),
            pageHeader: f.pageHeader,
            defaultValue: f.defaultValue,
            description: f.description
          }

          if (f.hasHelp) {
            this.$set(this.showFieldHelp, f.id, false)
          }

          if (storedData) {
            let storedField = storedData.find(sf => sf.id === f.id);
            if (storedField) {
              if (storedField.isEmpty) {
                row.isEmpty = storedField.isEmpty;
              } else if (storedField.value) {
                row.isEmpty = false;
                row.value = this.getPreparedStoredData(storedField.value, row);
              }
            }
          }
          this.tableData.push(row);
        }
      })
      this.setFieldFocus(true)
    },
    isEmptyByDefault(field) {
      return field.isEmptyByDefault ? field.isEmptyByDefault : false;
    }


  }
}
