import Enums from 'src/model/enums'
import {isDate, format, parseISO, differenceInMonths, differenceInYears} from 'date-fns'
import { getOldestDate, toEnum, hasNumericValue, hasPositiveNumericValue, hasZeroOrNullValue, getFieldValue, getTaxFormFieldValue, getTaxFormFields, getFieldValueAsNumberZeroed, hasPositive1040Wages } from 'src/util/seutil'
import {getConditionalWagesFieldName} from "@/util/seYearSpecific";
import SeIncomeType from "@/pages/Dashboard/Components/SeIncomeType.vue";

export default  {
  getNumberOfSelectedTaxFormYears: state => state.request.selectedTaxForms.filter(ty => ty.formIds && ty.formIds.length > 0).length,

  isDisplaySelectTaxYear: state => {
    return true
  },

  isDisplayBusScreens: (state, getters) => {
    return !getters.isRentalScheduleE
  },

  isSelfEmployed: state => {
    return state.request.incomeType === Enums.SeIncomeTypeType.selfEmployed
  },

  isRental: state => {
    return state.request.incomeType === Enums.SeIncomeTypeType.rental
  },

  isRentalScheduleE: state => {
    return state.request.incomeType === Enums.SeIncomeTypeType.rental
      && state.request.extraData.rentalIncomeIrsFormType === Enums.SeRentalIncomeIrsFormType.scheduleEPart1
  },

  isRentalBusiness: state => {
    return state.request.incomeType === Enums.SeIncomeTypeType.rental
      && state.request.extraData.rentalIncomeIrsFormType === Enums.SeRentalIncomeIrsFormType.businessTaxReturns
  },

  isLocked: state => {
    return !!state.request.locked
  },

  //returns object with previous years: PY1 and PY2
  getTaxYears: state => {
    let createdYear
    if (state.request.createdDate) {
      createdYear = new Date(state.request.createdDate).getFullYear()
    } else {
      createdYear = new Date().getFullYear()
    }

    let py1 = createdYear - 1
    let py2 = createdYear - 2
    if (!state.request.extraData.lastYearTaxReturnFiled) {
      py1--
      py2--
    }
    return {py1: py1.toString(), py2: py2.toString()}
  },

  getPY1: (state, getters) => {
    return getters.getTaxYears.py1;
  },

  getPY2: (state, getters) => {
    return getters.getTaxYears.py2;
  },

  getCalCy: (state, getters) => {
    return new Date().getFullYear();
  },

  getCalPy: (state, getters) => {
    return new Date().getFullYear() - 1;
  },

  //filters all available tax form configurations to only those that will be used for PY1 and PY2
  getConfTaxYears: (state, getters) => {
    let taxYears = getters.getTaxYears
    return state.conf.taxYears.filter(ty => ty.year == taxYears.py1 || ty.year == taxYears.py2)
  },

  getSelectedTaxYearsWithForms: state => {
    return state.request.selectedTaxForms
      .filter(stf => stf.formIds && stf.formIds.length > 0)
      .map(stf => stf.year)
  },

  //creates route flow and handles enable/disable of the specific form
  getTaxFormRoutes: (state, getters) => {
    return getters.getTaxFormActiveRoutes.map(r => r.route)
  },

  //creates route flow and handles enable/disable of the specific form
  getTaxFormActiveRoutes: (state, getters) => {
    let flowRoutes = [];

    let businessStructure = getters.getBusinessStructureEvaluatedAndDerived.evaluated
    let isEmploymentOwnershipInterestProvidedGte25OrNull = getters.isEmploymentOwnershipInterestProvidedGte25OrNull

    //Rental Schedule E
    if (getters.isRentalScheduleE) {
      getters.getTaxFormAllRoutes.forEach(r => {
        let include = false;

        if (r.year === getters.getPY1) {
          if ([Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tfLossesExpenses].includes(r.id)) {
            include = true

          } else if (r.id === Enums.SeTaxFormType.tf1040se && r.part === "1") {
            include = true

          } else if (r.id === Enums.SeTaxFormType.tf4684) {
            if ('Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tfLossesExpenses, 'form4684Included')) {
              include = true
            }
          }
        }

        if (include) {
          flowRoutes.push(r)
        }
      })

      //Sole prop
    } else if (businessStructure === Enums.SeBusinessStructureType.soleProprietorship) {
      getters.getTaxFormAllRoutes.forEach( r => {
        let include = false;

        if ([Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tfLossesExpenses].includes(r.id)) {
          include = true

        } else if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY1) {
          include = true

        } else if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY2) {
          //include Schedule C for prior year if it is not skipped which happens when the whole prior year is skipped
          let scheduleCDataInputSkippedPY2 = state.request.taxData.some(f => f.year === getters.getPY2 && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)
          if (!scheduleCDataInputSkippedPY2) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4684) {
          if ('Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tfLossesExpenses, 'form4684Included')) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4562) {
          let fields1040c = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1040c)
          if (r.part === "1"
              && hasPositiveNumericValue(fields1040c, "carTruckExpenses")
              && hasZeroOrNullValue(fields1040c, "businessMileage")) {
            include = true
          } else if (r.part === "2"
              && hasPositiveNumericValue(fields1040c, "otherExpenses")
              && hasZeroOrNullValue(fields1040c, "amortization")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tfw2) {
          let fields1040c = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1040c)
          if (hasPositiveNumericValue(fields1040c, "wagesLessEmploymentCredits") && hasPositive1040Wages(state.request.taxData, r.year)) {
            include = true
          }
        }

        if (include) {
          flowRoutes.push(r)
        }
      })

    } else if (businessStructure === Enums.SeBusinessStructureType.corp ||
               state.request.selectedTaxForms.some(stf => stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1120)) ) {

      //Corporation business structure OR 1120 forms were selected
      const allIndividualForms = [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr,
        Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se, Enums.SeTaxFormType.tfw2, Enums.SeTaxFormType.tf1125e]

      let flowRoutesBusiness = []
      getters.getTaxFormAllRoutes.forEach( r => {
        let include = false;

        //all 1040 forms go first
        if (allIndividualForms.includes(r.id)) {

          if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY1) {
            //include only if 1040c is skipped which happens in LLC/unknown flow
            if (state.request.taxData.some(f => f.year === r.year && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)) {
              flowRoutes.push(r)
            }

          } else if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY2) {
            //include Schedule C for prior year when Schedule C for current year is NOT skipped; exclude otherwise
            let scheduleCDataInputExistsPY1 = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && !f.skipped)
            if (scheduleCDataInputExistsPY1) {
              flowRoutes.push(r)
            }

          } else if (r.id === Enums.SeTaxFormType.tf1040se) {
            //include only if 1040c is skipped which happens in LLC/unknown flow
            if (r.part === "2" && state.request.taxData.some(f => f.year === r.year && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)) {
              flowRoutes.push(r)
            }

          } else {
            flowRoutes.push(r)
          }

        } else if (r.id === Enums.SeTaxFormType.tf4684) {
          if ('Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tfLossesExpenses, 'form4684Included')) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf8825) {
          let fields1065sk1 = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120)
          if (hasPositiveNumericValue(fields1065sk1, "netRentalRealEstateIncomeLoss")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4562) {
          let fields1120s = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120)
          if (r.part === "2"
            && hasPositiveNumericValue(fields1120s, "otherDeductions")
            && hasZeroOrNullValue(fields1120s, "amortization")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf1120m3) {
          if ('Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120, 'isM3Attached')) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf1120) {
          //do not include PY2 when business tax return is waived
          if (!state.request.extraData.waiveBusinessReturnsIndicator || r.year === getters.getPY1) {
            if (r.part === "1") {
              include = true
            } else if (r.part === "2" && 'Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tf1125e, 'allCorporationOwnersAreBorrowers')) {
              include = true
            }
          }

        } else if ([Enums.SeTaxFormType.tfLossesExpenses].includes(r.id)) {
          include = true
        }

        if (include) {
          flowRoutesBusiness.push(r)
        }
      })

      //1040 forms go first followed by business forms
      flowRoutes = flowRoutes.concat(flowRoutesBusiness)

      //Partnership
    } else if (businessStructure === Enums.SeBusinessStructureType.partnership ||
               state.request.selectedTaxForms.some(stf => stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1065)) ) {

      //Partnership business structure OR 1065 forms were selected
      const allIndividualForms = [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr,
        Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se, Enums.SeTaxFormType.tf1065sk1, Enums.SeTaxFormType.tfw2]

      let flowRoutesBusiness = []
      getters.getTaxFormAllRoutes.forEach( r => {
        let include = false;

        //all 1040 forms go first
        if (allIndividualForms.includes(r.id)) {

         if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY1) {
           //include only if 1040c is skipped which happens in LLC/unknown flow
           if (state.request.taxData.some(f => f.year === r.year && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)) {
             flowRoutes.push(r)
           }

          } else  if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY2) {
            //include Schedule C for prior year when Schedule C for current year is NOT skipped; exclude otherwise
           let scheduleCDataInputExistsPY1 = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && !f.skipped)
           if (scheduleCDataInputExistsPY1) {
              flowRoutes.push(r)
            }

          //always include Sch E Pg2
          } else if (r.id === Enums.SeTaxFormType.tf1040se) {
           if (r.part === "2") {
              flowRoutes.push(r)
           }

          } else if (r.id === Enums.SeTaxFormType.tfw2) {
           if (hasPositive1040Wages(state.request.taxData, r.year)) {
             flowRoutes.push(r)
           }

         } else  if (r.id === Enums.SeTaxFormType.tf1065sk1 && r.year === getters.getPY2) {
            let hide = getters.isEmploymentOwnershipInterestProvidedLt25
              && state.request.extraData.employmentOwnershipRecentAcquisitionIndicator
              && state.request.extraData.lastYearTaxReturnFiled
             if (!hide) {
               flowRoutes.push(r)
             }

          } else {
            flowRoutes.push(r)
          }

        } else if (r.id === Enums.SeTaxFormType.tf1065) {
          //do not include PY2 when business tax return is waived
          if (!state.request.extraData.waiveBusinessReturnsIndicator || r.year == getters.getPY1) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4684) {
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && 'Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tfLossesExpenses, 'form4684Included')) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf8825) {
          let fields1065sk1 = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1065sk1)
          if (hasPositiveNumericValue(fields1065sk1, "netRentalRealEstateIncomeLoss")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4562) {
          let fields1065 = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1065)
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && r.part === "2"
            && hasPositiveNumericValue(fields1065, "otherDeductions")
            && hasZeroOrNullValue(fields1065, "amortization")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf1065sm3) {
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && 'Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tf1065, 'isSchCAndM3Attached')) {
            include = true
          }

        } else if (isEmploymentOwnershipInterestProvidedGte25OrNull && [Enums.SeTaxFormType.tfLossesExpenses].includes(r.id)) {
          include = true
        }

        if (include) {
          flowRoutesBusiness.push(r)
        }
      })

      //1040 forms go first followed by business forms
      flowRoutes = flowRoutes.concat(flowRoutesBusiness)

      //S Corporation
    } else if (businessStructure === Enums.SeBusinessStructureType.sCorp ||
              state.request.selectedTaxForms.some(stf => stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1120s)) ) {

      //S Corporation OR 1120S forms were selected
      const allIndividualForms = [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr,
        Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se, Enums.SeTaxFormType.tf1120ssk, Enums.SeTaxFormType.tfw2]

      let flowRoutesBusiness = []
      getters.getTaxFormAllRoutes.forEach( r => {
        let include = false;

        //all 1040 forms go first
        if (allIndividualForms.includes(r.id)) {

          if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY1) {
            //include only if 1040c is skipped which happens in LLC/unknown flow
            if (state.request.taxData.some(f => f.year === r.year && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)) {
              flowRoutes.push(r)
            }

          } else if (r.id === Enums.SeTaxFormType.tf1040c && r.year === getters.getPY2) {
            //include Schedule C for prior year when Schedule C for current year is NOT skipped; exclude otherwise
            let scheduleCDataInputExistsPY1 = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && !f.skipped)
            if (scheduleCDataInputExistsPY1) {
              flowRoutes.push(r)
            }

          //always include Sch E Pg2
          } else if (r.id === Enums.SeTaxFormType.tf1040se) {
            if (r.part === "2") {
              flowRoutes.push(r)
            }

          } else if (r.id === Enums.SeTaxFormType.tfw2) {
            if (hasPositive1040Wages(state.request.taxData, r.year)) {
              flowRoutes.push(r)
            }

          } else {
            flowRoutes.push(r)
          }

        } else if (r.id === Enums.SeTaxFormType.tf1120s) {
          //do not include PY2 when business tax return is waived
          if ((!state.request.extraData.waiveBusinessReturnsIndicator && !state.request.extraData.skipIndividualPriorYearReturnsIndicator) || r.year == getters.getPY1) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4684) {
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && 'Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tfLossesExpenses, 'form4684Included')) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf8825) {
          let fields1120ssk = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120ssk)
          if (hasPositiveNumericValue(fields1120ssk, "netRentalRealEstateIncomeLoss")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf4562) {
          let fields1120s = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120s)
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && r.part === "2"
            && hasPositiveNumericValue(fields1120s, "otherDeductions")
            && hasZeroOrNullValue(fields1120s, "amortization")) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf1125e) {
          let fieldsW2 = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tfw2)
          let fields1120s = getTaxFormFields(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120s)
          if (hasPositive1040Wages(state.request.taxData, r.year)
            && hasZeroOrNullValue(fieldsW2, "w2Wages")
            && hasZeroOrNullValue(fieldsW2, "w2MedicareWages")
            && hasPositiveNumericValue(fields1120s, "compensationOfOfficers")
            && (getFieldValueAsNumberZeroed(fields1120s, "numberOfShareholders") > 1)) {
            include = true
          }

        } else if (r.id === Enums.SeTaxFormType.tf1120sm3) {
          if (isEmploymentOwnershipInterestProvidedGte25OrNull
            && 'Yes' === getTaxFormFieldValue(state.request.taxData, r.year, Enums.SeTaxFormType.tf1120s, 'isM3Attached')) {
            include = true
          }

        } else if (isEmploymentOwnershipInterestProvidedGte25OrNull && [Enums.SeTaxFormType.tfLossesExpenses].includes(r.id)) {
          include = true
          // console.log("S Corp default form", r.id)
        }

        if (include) {
          flowRoutesBusiness.push(r)
        }
      })

      //1040 forms go first followed by business forms
      flowRoutes = flowRoutes.concat(flowRoutesBusiness)

    } else if (businessStructure === Enums.SeBusinessStructureType.llc || businessStructure === Enums.SeBusinessStructureType.unknown) {
      getters.getTaxFormAllRoutes.forEach( r => {
        const llcFormFlow = [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040s1, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se]

        if (llcFormFlow.includes(r.id)) {
            if (r.id === Enums.SeTaxFormType.tf1040se) {
              let scheduleCDataInputSkipped = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)
              if (r.part === "2" && scheduleCDataInputSkipped) {
                flowRoutes.push(r)
              }
            } else {
              flowRoutes.push(r)
            }
        }
      })
    }
     return flowRoutes;
  },

  //returns all routes for selected tax forms
  getTaxFormAllRoutes: (state, getters) => {
    let allRoutes = [];
    state.request.selectedTaxForms.forEach(stf => {
      let year = stf.year;
      if (stf.formIds && stf.formIds.length > 0) {
        stf.formIds.forEach(formId => {
          if (year && formId) {
            const selectedForms = state.conf.taxYears
              .find(ty => ty.year === year).forms
              .filter(f => f.id === formId)
              .flatMap(rtn => rtn.forms)

            selectedForms.forEach(f => {
              let skipped = state.request.taxData.find(td => td.year === year && td.formId === f.id)?.skipped
              if (!skipped || [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se,
                  Enums.SeTaxFormType.tf1120s, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120].includes(f.id)) {

                if (f.parts) {
                  //do not include pages 2,3.. for skipped forms
                  allRoutes = allRoutes.concat(f.parts
                    .filter(part => !skipped || !part.part || part.part === '1')
                    .flatMap(part => { return {route: part.route, year, id: f.id, part: part.part} }))

                } else {
                  // add route form route
                  allRoutes.push( {route: f.route, year, id: f.id} )
                }
              }
            })
          }
        })

      }
    });
    return allRoutes
  },

  ///DERIVATIONS
  getSCorporationIncorporationDate: (state, getters) => {
    let incorporatedDatePY1 = getters.get1120sPY1?.find(field => field.id === 'incorporatedDate')?.value
    let incorporatedDatePY2 = getters.get1120sPY2?.find(field => field.id === 'incorporatedDate')?.value
    return getOldestDate(incorporatedDatePY1, incorporatedDatePY2)
  },

  getCorporationIncorporationDate: (state, getters) => {
    let incorporatedDatePY1 = getters.get1120PY1?.find(field => field.id === 'incorporatedDate')?.value
    let incorporatedDatePY2 = getters.get1120PY2?.find(field => field.id === 'incorporatedDate')?.value
    return getOldestDate(incorporatedDatePY1, incorporatedDatePY2)
  },

  getPartnershipBusinessStartedDate: (state, getters) => {
    let incorporatedDatePY1 = getters.get1065PY1?.find(field => field.id === 'businessStartedDate')?.value
    let incorporatedDatePY2 = getters.get1065PY2?.find(field => field.id === 'businessStartedDate')?.value
    return getOldestDate(incorporatedDatePY1, incorporatedDatePY2)
  },

  get1040cPY1: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1040c)?.fields
  },

  get1040cPY2: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY2 && form.formId === Enums.SeTaxFormType.tf1040c)?.fields
  },

  get1120sPY1: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1120s)?.fields
  },

  get1120sPY2: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY2 && form.formId === Enums.SeTaxFormType.tf1120s)?.fields
  },

  get1120PY1: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1120)?.fields
  },

  get1120PY2: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY2 && form.formId === Enums.SeTaxFormType.tf1120)?.fields
  },

  get1065PY1: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1065)?.fields
  },

  get1065PY2: (state, getters) => {
    return state.request.taxData.find(form => form.year === getters.getPY2 && form.formId === Enums.SeTaxFormType.tf1065)?.fields
  },

  getFirstTimeScheduleCFiledIndicatorPY1: (state, getters) => {
    return getters.get1040cPY1?.find(field => field.id === 'businessStartedAcquired')?.value
  },

  getFirstTimeScheduleCFiledIndicatorPY2: (state, getters) => {
    return getters.get1040cPY2?.find(field => field.id === 'businessStartedAcquired')?.value
  },

  getDerivedAutomobileInServiceSchCDate: (state, getters) => {
    let automobileInServiceSchCDatePY1 = getters.get1040cPY1?.find(field => field.id === 'vehicleServiceStartDate')?.value
    let automobileInServiceSchCDatePY2 = getters.get1040cPY2?.find(field => field.id === 'vehicleServiceStartDate')?.value
    return getOldestDate(automobileInServiceSchCDatePY1, automobileInServiceSchCDatePY2)
  },

  get1040SkippedPY2: (state, getters) => {
    return !state.request.taxData.some(form => form.year === getters.getPY2
      && !form.skipped
      && [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr].includes(form.formId))
  },

  getBusinessStructureProvided: (state, getters) => {
    return toEnum(Enums.SeBusinessStructureType, state.request.extraData.businessStructureProvided)
  },

  //evaluated business structure
  //derived description of the business structure
  getBusinessStructureEvaluatedAndDerived: (state, getters) => {
    let bs = {
      evaluated: Enums.SeBusinessStructureType.unknown,  //enum
      derived: Enums.SeBusinessStructureType.unknown.derivedName,  //string
    }

    let businessStructureProvided = getters.getBusinessStructureProvided
    if (state.request.incomeType === Enums.SeIncomeTypeType.rental) {
      bs.evaluated = Enums.SeBusinessStructureType.rental
      bs.derived = Enums.SeBusinessStructureType.rental.derivedName

    } else if (state.request.incomeType === Enums.SeIncomeTypeType.selfEmployed) {

      if (businessStructureProvided == Enums.SeBusinessStructureType.corp) {
        bs.evaluated = Enums.SeBusinessStructureType.corp
        bs.derived = Enums.SeBusinessStructureType.corp.derivedName

      } else if (businessStructureProvided == Enums.SeBusinessStructureType.partnership) {
        bs.evaluated = Enums.SeBusinessStructureType.partnership
        bs.derived = Enums.SeBusinessStructureType.partnership.derivedName

      } else if (businessStructureProvided == Enums.SeBusinessStructureType.sCorp) {
        bs.evaluated = Enums.SeBusinessStructureType.sCorp
        bs.derived = Enums.SeBusinessStructureType.sCorp.derivedName

      } else if (businessStructureProvided == Enums.SeBusinessStructureType.soleProprietorship) {
        bs.evaluated = Enums.SeBusinessStructureType.soleProprietorship
        bs.derived = Enums.SeBusinessStructureType.soleProprietorship.derivedName

      } else if (businessStructureProvided == Enums.SeBusinessStructureType.llc ||  businessStructureProvided == Enums.SeBusinessStructureType.unknown) {
        let isLlc = businessStructureProvided == Enums.SeBusinessStructureType.llc

        let any1065PY1 = state.request.taxData.some(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1065) ||
          state.request.selectedTaxForms.some(stf => stf.year === getters.getPY1 && stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1065))
        if (any1065PY1) {
          bs.evaluated = Enums.SeBusinessStructureType.partnership
          bs.derived = `${Enums.SeBusinessStructureType.partnership.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName: ''}`
          return bs
        }

        let any1120SPY1 = state.request.taxData.some(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1120s) ||
          state.request.selectedTaxForms.some(stf => stf.year === getters.getPY1 && stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1120s))
        if (any1120SPY1) {
          bs.evaluated = Enums.SeBusinessStructureType.sCorp
          bs.derived = `${Enums.SeBusinessStructureType.sCorp.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName : ''}`
          return bs
        }

        let any1120PY1 = state.request.taxData.some(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1120) ||
          state.request.selectedTaxForms.some(stf => stf.year === getters.getPY1 && stf.formIds && stf.formIds.length > 0 && stf.formIds.includes(Enums.SeTaxFormType.tf1120))
        if (any1120PY1) {
          bs.evaluated = Enums.SeBusinessStructureType.corp
          bs.derived = `${Enums.SeBusinessStructureType.corp.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName : ''}`
          return bs
        }

        let scheduleCDataInputExists = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && !f.skipped)
        let scheduleCDataInputSkipped = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040c && f.skipped)
        if (scheduleCDataInputExists) {
          bs.evaluated = Enums.SeBusinessStructureType.soleProprietorship
          bs.derived = `${Enums.SeBusinessStructureType.soleProprietorship.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName : ''}`
          return bs
        }

        let scheduleEDataInputExists = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040se && !f.skipped)
        let scheduleEDataInputSkipped = state.request.taxData.some(f => f.year === getters.getPY1 && f.formId === Enums.SeTaxFormType.tf1040se && f.skipped)
        let fields1040sePY1 = state.request.taxData.find(form => form.year === getters.getPY1 && form.formId === Enums.SeTaxFormType.tf1040se)?.fields
        let pOrS = getFieldValue(fields1040sePY1, 'pOrS')
        if (scheduleEDataInputExists) {

          if ( pOrS=== Enums.SeScheduleEPartnershipSCorpType.s) {
            bs.evaluated = Enums.SeBusinessStructureType.sCorp
            bs.derived = `${Enums.SeBusinessStructureType.sCorp.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName : ''}`
            return bs
          } else if (pOrS === Enums.SeScheduleEPartnershipSCorpType.p) {
            bs.evaluated = Enums.SeBusinessStructureType.partnership
            bs.derived = `${Enums.SeBusinessStructureType.partnership.derivedName} ${isLlc ? Enums.SeBusinessStructureType.llc.derivedName : ''}`
            return bs
          }
        } else if (isLlc && scheduleCDataInputSkipped && scheduleEDataInputSkipped) {
          bs.evaluated = Enums.SeBusinessStructureType.corp
          bs.derived = `${Enums.SeBusinessStructureType.corp.derivedName} ${Enums.SeBusinessStructureType.llc.derivedName}`
        }
      }
    }
    return bs
  },

  isFiveYearSelfEmploymentIndicator: (state, getters) => {
    if (!getters.getDerivedEmploymentBusinessStartDate) return false
    let count = differenceInYears(new Date(), getters.getDerivedEmploymentBusinessStartDate)
    return (Number.isNaN(count) ? 0 : count) >= 5
  },

  getEmploymentMonthsCount: (state, getters) => {
    if (!getters.getDerivedEmploymentBusinessStartDate) return 0
    let count = differenceInMonths(new Date(), getters.getDerivedEmploymentBusinessStartDate)
    return Number.isNaN(count) ? 0 : count
  },

  //derived employment start date - works for all business structures
  getDerivedEmploymentBusinessStartDate: (state, getters) => {
    let employmentStartProvidedDate = state.request.extraData.employmentStartDateProvided
    let derivedEmploymentBusinessStartDate = employmentStartProvidedDate
    return !derivedEmploymentBusinessStartDate || isDate(derivedEmploymentBusinessStartDate) ? derivedEmploymentBusinessStartDate : new Date(derivedEmploymentBusinessStartDate)
  },

  //prepares request from state and getters. This request will be used in save, eval and export calls
  getPreparedRequest: (state, getters) => {
    let preparedRequest = Object.assign({}, state.request)
    preparedRequest.extraData = Object.assign({}, state.request.extraData)

    //do not send duData and some other elements - they are not used by API
    delete preparedRequest.duData

    //TODO: drop eventually - this removes renamed fields. These fields were dropped/renamed and are no longer accepted by API
    const fieldsToDelete = [ [Enums.SeTaxFormType.tf1125e, "corporationOwnershipInterestType"] ]

    fieldsToDelete.forEach(ftd => {
      preparedRequest.taxData.filter(td => td.formId === ftd[0]).forEach(form => {
        if (form.fields) {
          let idx = form.fields.findIndex(f => f.id === ftd[1])
          if (idx !== -1) {
            form.fields.splice(idx, 1)
          }
        }
      })
    })

    preparedRequest.businessStructure = getters.getBusinessStructureEvaluatedAndDerived.evaluated.id
    preparedRequest.extraData.businessStructureDerived = getters.getBusinessStructureEvaluatedAndDerived.derived

    let employmentStartDate = getters.getDerivedEmploymentBusinessStartDate
    preparedRequest.employmentStartDate = employmentStartDate ? format(employmentStartDate, "yyyy-MM-dd") : employmentStartDate
    return preparedRequest
  },

  getBusinessIncomeIncreasingReturnWaiverIndicator: (state, getters) => {
    let businessReturnWaiverIncomeAmount = {}
    businessReturnWaiverIncomeAmount[getters.getPY1] = undefined
    businessReturnWaiverIncomeAmount[getters.getPY2] = undefined

    let businessStructure = getters.getBusinessStructureEvaluatedAndDerived.evaluated
    if (businessStructure === Enums.SeBusinessStructureType.sCorp) {

      Object.keys(businessReturnWaiverIncomeAmount).forEach(year => {
        let fields1120ssk = getTaxFormFields(state.request.taxData, year, Enums.SeTaxFormType.tf1120ssk)
        let fieldsW2 = getTaxFormFields(state.request.taxData, year, Enums.SeTaxFormType.tfw2)
        if (fields1120ssk || fieldsW2) {
          businessReturnWaiverIncomeAmount[year] = getFieldValueAsNumberZeroed(fields1120ssk, 'ordinaryBusinessIncomeLoss')
            + getFieldValueAsNumberZeroed(fields1120ssk, 'netRentalRealEstateIncomeLoss')
            + getFieldValueAsNumberZeroed(fields1120ssk, 'otherNetRentalIncomeLoss')
            + getFieldValueAsNumberZeroed(fieldsW2, 'w2MedicareWages')
        }
      })

    } else if (businessStructure === Enums.SeBusinessStructureType.partnership) {

      Object.keys(businessReturnWaiverIncomeAmount).forEach(year => {
        let fields1065sk1 = getTaxFormFields(state.request.taxData, year, Enums.SeTaxFormType.tf1065sk1)
        let fieldsW2 = getTaxFormFields(state.request.taxData, year, Enums.SeTaxFormType.tfw2)
        if (fields1065sk1 || fieldsW2) {
          businessReturnWaiverIncomeAmount[year] = getFieldValueAsNumberZeroed(fields1065sk1, 'ordinaryBusinessIncomeLoss')
            + getFieldValueAsNumberZeroed(fields1065sk1, 'netRentalRealEstateIncomeLoss')
            + getFieldValueAsNumberZeroed(fields1065sk1, 'otherNetRentalIncomeLoss')
            + getFieldValueAsNumberZeroed(fields1065sk1, 'guaranteedPaymentsForServices')
            + getFieldValueAsNumberZeroed(fields1065sk1, 'guaranteedPaymentsForCapital')
            + getFieldValueAsNumberZeroed(fieldsW2, 'w2MedicareWages')
        }
      })

    } else if (businessStructure === Enums.SeBusinessStructureType.corp) {

      Object.keys(businessReturnWaiverIncomeAmount).forEach(year => {
        let fieldsW2 = getTaxFormFields(state.request.taxData, year, Enums.SeTaxFormType.tfw2)
        if (fieldsW2) {
          businessReturnWaiverIncomeAmount[year] = getFieldValueAsNumberZeroed(fieldsW2, 'w2MedicareWages')
        }
      })
    }

    if (businessReturnWaiverIncomeAmount[getters.getPY1] === undefined || businessReturnWaiverIncomeAmount[getters.getPY2] === undefined) {
      return false
    }

    // console.log("getBusinessIncomeIncreasingReturnWaiverIndicator", businessReturnWaiverIncomeAmount)
    return (businessReturnWaiverIncomeAmount[getters.getPY1] > businessReturnWaiverIncomeAmount[getters.getPY2]) && (businessReturnWaiverIncomeAmount[getters.getPY1] > 0)
  },

  getHintAmount: (state, getters) => {
    let amount = state.request.taxData
      .filter(form => [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr, Enums.SeTaxFormType.tfw2].includes(form.formId))
      .flatMap(form => [getFieldValueAsNumberZeroed(form.fields, 'otherIncomeLoss'), getFieldValueAsNumberZeroed(form.fields, 'w2MedicareWages')])
      .find(v => v !== 0)

    return amount ?? 0
  },

  isEmploymentOwnershipInterestProvidedGte25OrNull: state => {
    return !state.request.extraData.employmentOwnershipInterestProvided
      || state.request.extraData.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.gte25;
  },

  isEmploymentOwnershipInterestProvidedLt25: state => {
    return state.request.extraData.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.lt25;
  }

}

