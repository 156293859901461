import {
  GET_SE_REQUEST,
  GET_SE_REQUEST_SUCCESS,
  GET_SE_REQUEST_ERROR,
  CHECK_CONVERSATION_ID_SUCCESS,
  CHECK_CONVERSATION_ID_ERROR,
  SET_REQUEST_DATA,
  SET_STATE_DATA,
  SET_TAX_FORM_DATA,
  SAVE_SE_REQUEST,
  SAVE_SE_REQUEST_SUCCESS,
  SAVE_SE_REQUEST_ERROR,
  EVAL_SE_REQUEST,
  EVAL_SE_REQUEST_SUCCESS,
  EVAL_SE_REQUEST_ERROR,
  GET_SE_REQUEST_FROM_FILE,
  RETAIN_TAX_DATA,
  CREATE_CASEFILE_ASSOCIATION,
  REMOVE_CASEFILE_ASSOCIATION,
  SKIP_YEAR_TAX_FORMS,
  SET_EXTRA_DATA,
  GET_SE_CASEFILE,
  GET_SE_CASEFILE_SUCCESS,
  RESET_STATE,
  RESET_SELECTED_TAX_FORMS,
  RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS,
  DESELECT_BUSINESS_FORMS_PY,
  SKIP_BUSINESS_TAX_FORMS,
  PURGE_HIDDEN_TAX_FORMS,
  DELETE_TAX_FORM,
  CHECK_CONVERSATION_ID,
  SET_CLONED_DATA
} from '../actions/se'

import Vue from 'vue'
import Enums from 'src/model/enums'
import seApi from 'src/util/seapi'
import getters from  './getters';

import tf1040_2023 from './conf/2023/tf1040'
import tf1040s1_2023 from './conf/2023/tf1040s1'
import tf1040se_2023 from './conf/2023/tf1040se'
import tf1040c_2023 from './conf/2023/tf1040c'
import tf4684_2023 from './conf/2023/tf4684'
import tf4562_2023 from './conf/2023/tf4562'
import tf1040sr_2023 from './conf/2023/tf1040sr'
import tf1040nr_2023 from './conf/2023/tf1040nr'
import tfw2_2023 from './conf/2023/tfW2'
import tf1120s_2023 from './conf/2023/tf1120s'
import tf1120ssk_2023 from './conf/2023/tf1120ssk'
import tf8825_2023 from './conf/2023/tf8825'
import tf1120sm3_2023 from './conf/2023/tf1120sm3'
import tf1065sk1_2023 from './conf/2023/tf1065sk1'
import tf1065_2023 from './conf/2023/tf1065'
import tf1065sm3_2023 from './conf/2023/tf1065sm3'
import tfLossesExpenses_2023 from './conf/2023/tfLossesExpenses'
import tf1125e_2023 from './conf/2023/tf1125e'
import tf1120_2023 from './conf/2023/tf1120'
import tf1120m3_2023 from './conf/2023/tf1120m3'

import tf1040_2022 from './conf/2022/tf1040'
import tf1040s1_2022 from './conf/2022/tf1040s1'
import tf1040se_2022 from './conf/2022/tf1040se'
import tf1040c_2022 from './conf/2022/tf1040c'
import tf4684_2022 from './conf/2022/tf4684'
import tf4562_2022 from './conf/2022/tf4562'
import tf1040sr_2022 from './conf/2022/tf1040sr'
import tf1040nr_2022 from './conf/2022/tf1040nr'
import tfw2_2022 from './conf/2022/tfW2'
import tf1120s_2022 from './conf/2022/tf1120s'
import tf1120ssk_2022 from './conf/2022/tf1120ssk'
import tf8825_2022 from './conf/2022/tf8825'
import tf1120sm3_2022 from './conf/2022/tf1120sm3'
import tf1065sk1_2022 from './conf/2022/tf1065sk1'
import tf1065_2022 from './conf/2022/tf1065'
import tf1065sm3_2022 from './conf/2022/tf1065sm3'
import tfLossesExpenses_2022 from './conf/2022/tfLossesExpenses'
import tf1125e_2022 from './conf/2022/tf1125e'
import tf1120_2022 from './conf/2022/tf1120'
import tf1120m3_2022 from './conf/2022/tf1120m3'

import tf1040_2021 from './conf/2021/tf1040'
import tf1040s1_2021 from './conf/2021/tf1040s1'
import tf1040se_2021 from './conf/2021/tf1040se'
import tf1040c_2021 from './conf/2021/tf1040c'
import tf4684_2021 from './conf/2021/tf4684'
import tf4562_2021 from './conf/2021/tf4562'
import tf1040sr_2021 from './conf/2021/tf1040sr'
import tf1040nr_2021 from './conf/2021/tf1040nr'
import tfw2_2021 from './conf/2021/tfW2'
import tf1120s_2021 from './conf/2021/tf1120s'
import tf1120ssk_2021 from './conf/2021/tf1120ssk'
import tf8825_2021 from './conf/2021/tf8825'
import tf1120sm3_2021 from './conf/2021/tf1120sm3'
import tf1065sk1_2021 from './conf/2021/tf1065sk1'
import tf1065_2021 from './conf/2021/tf1065'
import tf1065sm3_2021 from './conf/2021/tf1065sm3'
import tfLossesExpenses_2021 from './conf/2021/tfLossesExpenses'
import tf1125e_2021 from './conf/2021/tf1125e'
import tf1120_2021 from './conf/2021/tf1120'
import tf1120m3_2021 from './conf/2021/tf1120m3'

import tf1040_2020 from './conf/2020/tf1040'
import tf1040s1_2020 from './conf/2020/tf1040s1'
import tf1040se_2020 from './conf/2020/tf1040se'
import tf1040c_2020 from './conf/2020/tf1040c'
import tf4684_2020 from './conf/2020/tf4684'
import tf4562_2020 from './conf/2020/tf4562'
import tf1040sr_2020 from './conf/2020/tf1040sr'
import tf1040nr_2020 from './conf/2020/tf1040nr'
import tfw2_2020 from './conf/2020/tfW2'
import tf1120s_2020 from './conf/2020/tf1120s'
import tf1120ssk_2020 from './conf/2020/tf1120ssk'
import tf8825_2020 from './conf/2020/tf8825'
import tf1120sm3_2020 from './conf/2020/tf1120sm3'
import tf1065sk1_2020 from './conf/2020/tf1065sk1'
import tf1065_2020 from './conf/2020/tf1065'
import tf1065sm3_2020 from './conf/2020/tf1065sm3'
import tfLossesExpenses_2020 from './conf/2020/tfLossesExpenses'
import tf1125e_2020 from './conf/2020/tf1125e'
import tf1120_2020 from './conf/2020/tf1120'
import tf1120m3_2020 from './conf/2020/tf1120m3'

import {toEnum, processLoadedRequest} from "@/util/seutil";
import {format} from "date-fns";


//some generic rules
// mutation is the only way to modify state
// mutation does not care about the business logic, it just cares about the "state" and are always sync
// action is business logic
// action can dispatch more than 1 mutation, it just implements the business logic, it does not care about data changing (which is managed by mutation)


const getDefaultState = () => {
  return {
    status: null,
    errorMessage: null,
    acceptedTerms: false,
    visitedFindings: false,
    isSavingTaxFormData: false,
    externalMode: false,
    accessAllowed: false,
    conversationId: "",
    caseFileSearchResult: [],
    request: {
      requestId: '',
      requestType: '',
      createdDate: new Date(),
      locked: false,
      hidden: false,
      userType: Enums.SeUserType.utBrokerLender,
      brokerOriginatorId: '',
      otherAgentId: '',
      incomeType: Enums.SeIncomeTypeType.selfEmployed,
      refName: '',
      duCaseFileId: null,
      selectedTaxForms: [
        {"year": "2023", "formIds": []},
        {"year": "2022", "formIds": []},
        {"year": "2021", "formIds": []},
        {"year": "2020", "formIds": []}
      ],
      businessStructure: null,
      employmentStartDate: '',
      employmentHistoryConsistentIndicator: false,
      employmentOwnershipFiveYearHistoryIndicator: true,
      taxData: [],
      duData: {
        caseFileDate: '',
        employmentStartDate: null,
        documentationLevel: null,
        automatedUnderwritingSubmissionDate: ''
      },
      extraData: {
        lastYearTaxReturnFiled: true,
        evaluationDataSource: Enums.SeEvaluationDataSourceType.dsManual,
        businessStructureProvided: Enums.SeBusinessStructureType.soleProprietorship.id,
        employmentOwnershipInterestProvided: Enums.EmploymentOwnershipInterestProvidedType.gte25,
        employmentOwnershipRecentAcquisitionIndicator: false,
        employmentStartDateProvided: '',
        businessStructureDerived: null,
        waiveBusinessReturnsIndicator: false,
        skipIndividualPriorYearReturnsIndicator: false,
        rentalIncomeIrsFormType: null
      }
    },
    response: {
      taxYears: [
        {year: "2023"}, {year: "2022"}
      ]
    },
    conf: {
      taxYears: [
        {
          year: "2023",
          vehicleDepreciationRate: 28,
          forms: [
            {
              id: Enums.SeTaxFormType.tf1040,
              name: 'U.S. Individual Income Tax Return',
              forms: [
                tf1040_2023,
                tf1040s1_2023
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040sr,
              indent: true,
              name: 'U.S. Tax Return for Seniors',
              forms: [
                tf1040sr_2023,
                tf1040s1_2023
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040nr,
              indent: true,
              name: 'U.S. Nonresident Alien Income Tax Return',
              forms: [
                tf1040nr_2023,
                tf1040s1_2023
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040c,
              hidden: true,
              name: 'Sole Proprietor - Schedule C',
              forms: [
                tf1040c_2023,
                tf4562_2023,
                tf1040se_2023,
                tfLossesExpenses_2023,
                tf4684_2023,
                tf1120ssk_2023,
                tf1065sk1_2023,
                tfw2_2023,
                tf1125e_2023,

                //to support unknown/llc and business tax return waiver
                tf8825_2023,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040se,
              hidden: true,
              name: 'Rental - Schedule E',
              forms: [
                tf1040se_2023,
                tfLossesExpenses_2023,
                tf4684_2023
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1065,
              name: 'U.S. Return of Partnership Income',
              forms: [
                tf1040c_2023,
                tf1040se_2023,
                tf1065sk1_2023,
                tfw2_2023,
                tf1065_2023,
                tf4562_2023,
                tfLossesExpenses_2023,
                tf4684_2023,
                tf8825_2023,
                tf1065sm3_2023,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120,
              name: 'U.S. Corporation Income Tax Return',
              forms: [
                tf1040c_2023,
                tf1040se_2023,
                tfw2_2023,
                tf1125e_2023,
                tf1120_2023,
                tf4562_2023,
                tfLossesExpenses_2023,
                tf4684_2023,
                tf1120m3_2023
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120s,
              name: 'U.S. Income Tax Return for an S Corporation',
              forms: [
                tf1040c_2023,
                tf1040se_2023,
                tf1120ssk_2023,
                tfw2_2023,
                tf1120s_2023,
                tf4562_2023,
                tf1125e_2023,
                tfLossesExpenses_2023,
                tf4684_2023,
                tf8825_2023,
                tf1120sm3_2023,
              ]
            },
          ]
        },
        {
          year: "2022",
          vehicleDepreciationRate: 26,
          forms: [
            {
              id: Enums.SeTaxFormType.tf1040,
              name: 'U.S. Individual Income Tax Return',
              forms: [
                tf1040_2022,
                tf1040s1_2022
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040sr,
              indent: true,
              name: 'U.S. Tax Return for Seniors',
              forms: [
                tf1040sr_2022,
                tf1040s1_2022
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040nr,
              indent: true,
              name: 'U.S. Nonresident Alien Income Tax Return',
              forms: [
                tf1040nr_2022,
                tf1040s1_2022
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040c,
              hidden: true,
              name: 'Sole Proprietor - Schedule C',
              forms: [
                tf1040c_2022,
                tf4562_2022,
                tf1040se_2022,
                tfLossesExpenses_2022,
                tf4684_2022,
                tf1120ssk_2022,
                tf1065sk1_2022,
                tfw2_2022,
                tf1125e_2022,

                //to support unknown/llc and business tax return waiver
                tf8825_2022,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040se,
              hidden: true,
              name: 'Rental - Schedule E',
              forms: [
                tf1040se_2022,
                tfLossesExpenses_2022,
                tf4684_2022
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1065,
              name: 'U.S. Return of Partnership Income',
              forms: [
                tf1040c_2022,
                tf1040se_2022,
                tf1065sk1_2022,
                tfw2_2022,
                tf1065_2022,
                tf4562_2022,
                tfLossesExpenses_2022,
                tf4684_2022,
                tf8825_2022,
                tf1065sm3_2022,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120,
              name: 'U.S. Corporation Income Tax Return',
              forms: [
                tf1040c_2022,
                tf1040se_2022,
                tfw2_2022,
                tf1125e_2022,
                tf1120_2022,
                tf4562_2022,
                tfLossesExpenses_2022,
                tf4684_2022,
                tf1120m3_2022
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120s,
              name: 'U.S. Income Tax Return for an S Corporation',
              forms: [
                tf1040c_2022,
                tf1040se_2022,
                tf1120ssk_2022,
                tfw2_2022,
                tf1120s_2022,
                tf4562_2022,
                tf1125e_2022,
                tfLossesExpenses_2022,
                tf4684_2022,
                tf8825_2022,
                tf1120sm3_2022,
              ]
            },
          ]
        },
        {
          year: "2021",
          vehicleDepreciationRate: 26,
          forms: [
            {
              id: Enums.SeTaxFormType.tf1040,
              name: 'U.S. Individual Income Tax Return',
              forms: [
                tf1040_2021,
                tf1040s1_2021
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040sr,
              indent: true,
              name: 'U.S. Tax Return for Seniors',
              forms: [
                tf1040sr_2021,
                tf1040s1_2021
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040nr,
              indent: true,
              name: 'U.S. Nonresident Alien Income Tax Return',
              forms: [
                tf1040nr_2021,
                tf1040s1_2021
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040c,
              hidden: true,
              name: 'Sole Proprietor - Schedule C',
              forms: [
                tf1040c_2021,
                tf4562_2021,
                tf1040se_2021,
                tfLossesExpenses_2021,
                tf4684_2021,
                tf1120ssk_2021,
                tf1065sk1_2021,
                tfw2_2021,
                tf1125e_2021,

                //to support unknown/llc and business tax return waiver
                tf8825_2021,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040se,
              hidden: true,
              name: 'Rental - Schedule E',
              forms: [
                tf1040se_2021,
                tfLossesExpenses_2021,
                tf4684_2021
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1065,
              name: 'U.S. Return of Partnership Income',
              forms: [
                tf1040c_2021,
                tf1040se_2021,
                tf1065sk1_2021,
                tfw2_2021,
                tf1065_2021,
                tf4562_2021,
                tfLossesExpenses_2021,
                tf4684_2021,
                tf8825_2021,
                tf1065sm3_2021,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120,
              name: 'U.S. Corporation Income Tax Return',
              forms: [
                tf1040c_2021,
                tf1040se_2021,
                tfw2_2021,
                tf1125e_2021,
                tf1120_2021,
                tf4562_2021,
                tfLossesExpenses_2021,
                tf4684_2021,
                tf1120m3_2021
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120s,
              name: 'U.S. Income Tax Return for an S Corporation',
              forms: [
                tf1040c_2021,
                tf1040se_2021,
                tf1120ssk_2021,
                tfw2_2021,
                tf1120s_2021,
                tf4562_2021,
                tf1125e_2021,
                tfLossesExpenses_2021,
                tf4684_2021,
                tf8825_2021,
                tf1120sm3_2021,
              ]
            },
          ]
        },
        {
          year: "2020",
          vehicleDepreciationRate: 27,
          forms: [
            {
              id: Enums.SeTaxFormType.tf1040,
              name: 'U.S. Individual Income Tax Return',
              forms: [
                tf1040_2020,
                tf1040s1_2020
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040sr,
              indent: true,
              name: 'U.S. Tax Return for Seniors',
              forms: [
                tf1040sr_2020,
                tf1040s1_2020
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040nr,
              indent: true,
              name: 'U.S. Nonresident Alien Income Tax Return',
              forms: [
                tf1040nr_2020,
                tf1040s1_2020
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040c,
              hidden: true,
              name: 'Sole Proprietor - Schedule C',
              forms: [
                tf1040c_2020,
                tf4562_2020,
                tf1040se_2020,
                tfLossesExpenses_2020,
                tf4684_2020,
                tf1120ssk_2020,
                tf1065sk1_2020,
                tfw2_2020,
                tf1125e_2020,

                //to support unknown/llc and business tax return waiver
                tf8825_2020,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1040se,
              hidden: true,
              name: 'Rental - Schedule E',
              forms: [
                tf1040se_2020,
                tfLossesExpenses_2020,
                tf4684_2020
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1065,
              name: 'U.S. Return of Partnership Income',
              forms: [
                tf1040c_2020,
                tf1040se_2020,
                tf1065sk1_2020,
                tfw2_2020,
                tf1065_2020,
                tf4562_2020,
                tfLossesExpenses_2020,
                tf4684_2020,
                tf8825_2020,
                tf1065sm3_2020,
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120,
              name: 'U.S. Corporation Income Tax Return',
              forms: [
                tf1040c_2020,
                tf1040se_2020,
                tfw2_2020,
                tf1125e_2020,
                tf1120_2020,
                tf4562_2020,
                tfLossesExpenses_2020,
                tf4684_2020,
                tf1120m3_2020
              ]
            },
            {
              id: Enums.SeTaxFormType.tf1120s,
              name: 'U.S. Income Tax Return for an S Corporation',
              forms: [
                tf1040c_2020,
                tf1040se_2020,
                tf1120ssk_2020,
                tfw2_2020,
                tf1120s_2020,
                tf4562_2020,
                tf1125e_2020,
                tfLossesExpenses_2020,
                tf4684_2020,
                tf8825_2020,
                tf1120sm3_2020,
              ]
            }
          ]
        }
      ]
    },
  }
}

const state = getDefaultState()

const actions = {
  [GET_SE_REQUEST]: ({commit, dispatch}, {requestId, incomeAmountHint}) => {
    return seApi.getRequest(requestId, incomeAmountHint)
      .then(resp => {
        let requestData = processLoadedRequest(resp.data)
        commit(GET_SE_REQUEST_SUCCESS, requestData)
      })
      // .catch(resp => {
      //   console.log("error getting")
      //   commit(GET_SE_REQUEST_ERROR)
      // })
  },

  [GET_SE_REQUEST_FROM_FILE]: ({commit, dispatch}, fileName) => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        reader.onload = (e) => {
          try {
            let requestData = JSON.parse(e.target.result);
            if (requestData && requestData.taxData) {
                requestData.requestId = '';
                requestData.locked = false;
                requestData = processLoadedRequest(requestData)
                commit(GET_SE_REQUEST_SUCCESS, requestData)
            } else {
                throw new Error(`Corrupted file: ${fileName}`);
            }
            resolve(e.target.result);
          } catch (e) {
              commit(GET_SE_REQUEST_ERROR, e.message)
              console.log(`Corrupted file: ${fileName}`, e.message);
              reject(new DOMException("Problem parsing input file."));
          }
        };
        reader.readAsText(fileName);
      });

  },

  [SAVE_SE_REQUEST]: ({commit, getters}, forceSave) => {
    if (state.request.locked && !forceSave) return

    return seApi.saveRequest(getters.getPreparedRequest)
      .then(resp => {
        commit(SAVE_SE_REQUEST_SUCCESS, {status: resp.status, location: resp.headers.location})
      })
  },

  [EVAL_SE_REQUEST]: ({commit, getters}) => {
    return seApi.evaluate(getters.getPreparedRequest)
      .then(resp => {
        commit(EVAL_SE_REQUEST_SUCCESS, resp.data)
        commit(SET_REQUEST_DATA, {requestId : resp.data.requestId})
      })
      .catch(resp => {
        commit(EVAL_SE_REQUEST_ERROR)
      })
  },

  [GET_SE_CASEFILE]: ({commit, dispatch}, {duCaseFileId}) => {
    commit(GET_SE_CASEFILE_SUCCESS, {})
    return seApi.getCaseFile(duCaseFileId)
      .then(resp => {
        commit(GET_SE_CASEFILE_SUCCESS, resp.data)
        return resp;
      })
    .catch( ({response}) => {
        throw response
      })
      // .catch( ({response}) => {
      //   console.log("error getting", response.data)
      //   console.log("error getting", response.status)
      //   console.log("error getting", response.headers)
      // })
  },

  // [GET_SE_CASEFILE_SUCCESS]: (state, data) => {
  //   console.log("in GET_SE_CASEFILE_SUCCESS")
  //   state.status = Enums.SeStatus.success
  //   Vue.set(state, 'casefile', data)
  //   console.log("end GET_SE_CASEFILE_SUCCESS")
  // },

  [CREATE_CASEFILE_ASSOCIATION]: ({commit, dispatch}, {casefileId, zip, propertyZip, employmentStartDate, incomeAmount}) => {
    return seApi.createCaseFileAssociation(casefileId, state.request.requestId, zip, propertyZip, employmentStartDate, incomeAmount)
      .then(resp => {
        //the "resp" is expected to return data pulled from DU, including the case file creation date 'duCaseFileDate'
        if (!resp.data?.associationSuccessIndicator) {
          throw new Error('Association failed');
        }
        commit(SET_REQUEST_DATA, {ignoreLocked:true, duCaseFileId: casefileId, duData: resp.data})
      })
      .catch( ({response}) => {
        commit(SET_REQUEST_DATA, {ignoreLocked:true, duCaseFileId: null, duData: null})
        throw response
      })
  },

  [REMOVE_CASEFILE_ASSOCIATION]: ({commit, dispatch}) => {
    return seApi.removeCaseFileAssociation(state.request.duCaseFileId, state.request.requestId)
      .then(resp => {
        //casefile was disassociated - empty the id and date
        commit(SET_REQUEST_DATA, {ignoreLocked:true, duCaseFileId: null, duData: null})
      })
  },

  //Updates skipped flag for all active forms in the specified year
  [SKIP_YEAR_TAX_FORMS]: ({commit, dispatch}, {year, skipped}) => {
    state.request.selectedTaxForms
    .filter(stf => stf.year === year)
    .forEach(stf => {
      if (stf.formIds && stf.formIds.length > 0) {
        stf.formIds.forEach(formId => {

          const selectedForms = state.conf.taxYears
            .find(ty => ty.year === year).forms
            .filter(rtn => rtn.id === formId)
            .flatMap(rtn => rtn.forms)

          selectedForms.forEach(f => {
            commit(SET_TAX_FORM_DATA, {year, formId: f.id, skipped, fields: [] })
          })
        })
      }
    })
    commit(SET_EXTRA_DATA, {skipIndividualPriorYearReturnsIndicator: skipped})
  },

  //Updates skipped flag for all active forms in the specified year
  [SKIP_BUSINESS_TAX_FORMS]: ({commit, dispatch}, {skipped}) => {

    state.request.selectedTaxForms
    .forEach(stf => {
      if (stf.formIds && stf.formIds.length > 0) {
        stf.formIds.forEach(formId => {

          const selectedForms = state.conf.taxYears
            .find(ty => ty.year === stf.year).forms
            .filter(rtn => rtn.id === formId)
            .flatMap(rtn => rtn.forms)

          selectedForms.forEach(f => {
            if (f.waivable) {
              commit(SET_TAX_FORM_DATA, {year: stf.year, formId: f.id, skipped, fields: []})
            }
          })
        })
      }
    })
    commit(SET_EXTRA_DATA, {waiveBusinessReturnsIndicator: skipped})
  },

  //removes hidden tax forms
  [PURGE_HIDDEN_TAX_FORMS]: ({commit, getters}) => {
    let formsToPurge = state.request.taxData
      .filter(td => !getters.getTaxFormActiveRoutes.some(activeForm => td.skipped || (activeForm.year === td.year && activeForm.id === td.formId)))
    if (formsToPurge && formsToPurge.length > 0) {
      commit(DELETE_TAX_FORM, formsToPurge)
    }
  },

  [CHECK_CONVERSATION_ID]: ({commit, dispatch}) => {
    return seApi.checkConversationId()
      .then(response => {
        commit(CHECK_CONVERSATION_ID_SUCCESS)
      })
      .catch( ({response}) => {
        commit(CHECK_CONVERSATION_ID_ERROR)
        throw response
      })
  },

}

const mutations= {
  [RESET_STATE](state) {
    let conversationId = state.conversationId
    let externalMode = state.externalMode
    //reset to empty
    Object.assign(state, getDefaultState())
    Vue.set(state, 'acceptedTerms', true)
    Vue.set(state, 'accessAllowed', true)
    Vue.set(state, 'conversationId', conversationId)
    Vue.set(state, 'externalMode', externalMode)
  },

  [RESET_SELECTED_TAX_FORMS](state) {
    //reset to empty
    Object.assign(state.request.selectedTaxForms, getDefaultState().request.selectedTaxForms)
  },

  [SET_STATE_DATA](state, {key, value}) {
    Vue.set(state, key, value)
  },

  [SET_REQUEST_DATA](state, data) {
    //certain updates are allowed even when record is locked
    //those need to be passed with ignoreLocked = true
    if (state.request.locked && !data?.ignoreLocked)
      return

    if (data.ignoreLocked)
      delete data.ignoreLocked

    //merge data with previous state
    state.request = Object.assign({}, state.request, data)
  },

  [SET_CLONED_DATA](state, data) {
    //setup the request as clone, clearing some fields
    data['duCaseFileId'] = null
    data['duData'] = null
    state.request = Object.assign({}, state.request, data)
  },

  [SET_TAX_FORM_DATA](state, {year, formId, skipped, fields}) {
    if (state.request.locked) return

    let storedData = {};
    storedData['year'] = year;
    storedData['formId'] = formId;
    storedData['skipped'] = skipped;
    storedData['fields'] = fields;

    let form = state.request.taxData.find(f => f.year === year && f.formId === formId);
    if (form) {
      //as "fields" array may contain just a part of the form, we need to merge it with the existing fields
      if (form.fields) {
        form.fields.forEach(existingField => {
          if (!fields.some(newField => newField.id === existingField.id)) {
            //existing field is not in the new fields set, lets merge
            fields.push(existingField)
          }
        })
      }

      Vue.set(form, 'skipped', skipped)
      Vue.set(form, 'fields', fields)
    } else {
      state.request.taxData.push(storedData);
    }
  },

  [DELETE_TAX_FORM](state, formsToDelete) {
    if (state.request.locked) return

    formsToDelete.forEach(formToDelete => {
      let i = state.request.taxData.findIndex(td => td.year === formToDelete.year && td.formId === formToDelete.formId)
      if (i !== -1) {
        state.request.taxData.splice(i, 1)
      }
    })
  },

  [RETAIN_TAX_DATA](state, retainedForms) {
    if (state.request.locked) return

    if (state.request.taxData && state.request.taxData.length > 0 && retainedForms && retainedForms.length > 0) {
      let allRetainedTaxData = state.request.taxData.filter(td => retainedForms.some(f => f.year === td.year && f.id === td.formId));
      state.request.taxData.splice(0);
      if (allRetainedTaxData) {
        allRetainedTaxData.forEach(td => state.request.taxData.push(td))
      }
    }
  },

  //remove selected tax forms not compatible with business structure
  [RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS](state) {
    if (state.request.locked) return

    let busStructure = toEnum(Enums.SeBusinessStructureType, state.request.extraData.businessStructureProvided)
    state.request.selectedTaxForms.forEach(stf => {
      if (stf.formIds && stf.formIds.length > 0) {

        if (stf.formIds.includes(Enums.SeTaxFormType.tf1120s) && ![Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(busStructure)) {
          stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1120s), 1)
        } else if (stf.formIds.includes(Enums.SeTaxFormType.tf1120) && ![Enums.SeBusinessStructureType.corp, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(busStructure)) {
          stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1120), 1)
        } else if (stf.formIds.includes(Enums.SeTaxFormType.tf1065) && ![Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(busStructure)) {
          stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1065), 1)
        }
        if (stf.formIds.length === 1 && stf.formIds.includes(Enums.SeTaxFormType.tf1040)) {
          if (state.request.incomeType === Enums.SeIncomeTypeType.rental) {
            stf.formIds.push(Enums.SeTaxFormType.tf1040se)
          } else {
            stf.formIds.push(Enums.SeTaxFormType.tf1040c)
          }
        }
      }
    })
  },

  [DESELECT_BUSINESS_FORMS_PY](state, year) {
    if (state.request.locked) return
    if (state.request.extraData.employmentOwnershipInterestProvided !== Enums.EmploymentOwnershipInterestProvidedType.lt25) return

    state.request.selectedTaxForms
      .filter(ty => ty.year === year)
      .forEach(stf => {
        if (stf.formIds && stf.formIds.length > 0) {

          if (stf.formIds.includes(Enums.SeTaxFormType.tf1120s)) {
            stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1120s), 1)

          } else if (stf.formIds.includes(Enums.SeTaxFormType.tf1120) ) {
            stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1120), 1)

          } else if (stf.formIds.includes(Enums.SeTaxFormType.tf1065) ) {
            stf.formIds.splice(stf.formIds.indexOf(Enums.SeTaxFormType.tf1065), 1)
          }
        }
    })
  },

  [GET_SE_REQUEST]: (state, requestId) => {
    state.status = Enums.SeStatus.loading
    state.seRequestId = requestId
  },

  [SET_EXTRA_DATA](state, data) {
    if (state.request.locked) return
    //merge data with previous state
    state.request.extraData = Object.assign({}, state.request.extraData, data)
  },

  [GET_SE_REQUEST_SUCCESS]: (state, data) => {
    state.status = Enums.SeStatus.success
    Vue.set(state, 'request', data)
  },

  [GET_SE_REQUEST_ERROR]: (state, message) => {
    state.status = Enums.SeStatus.error
    state.errorMessage = message
  },

  [CHECK_CONVERSATION_ID_SUCCESS]: (state, data) => {
    state.accessAllowed = true
  },

  [CHECK_CONVERSATION_ID_ERROR]: (state, message) => {
    state.accessAllowed = false
  },

  [GET_SE_CASEFILE_SUCCESS]: (state, data) => {
    state.status = Enums.SeStatus.success
    Vue.set(state, 'caseFileSearchResult', data)
  },

  [SAVE_SE_REQUEST_SUCCESS]: (state, resp) => {
    state.status = Enums.SeStatus.success
    if (resp.status == 201) {
      let requestId = resp.location.substr(resp.location.lastIndexOf("/") + 1);
      state.request.requestId = requestId;
    }

  },
  [SAVE_SE_REQUEST_ERROR]: (state) => {
    state.status = Enums.SeStatus.error
    state.status = 'Could not save request'
  },

  [EVAL_SE_REQUEST_SUCCESS]: (state, data) => {
    state.status = Enums.SeStatus.success
    Vue.set(state, 'response', data)
  },

  [EVAL_SE_REQUEST_ERROR]: (state) => {
    state.status = Enums.SeStatus.error
    state.errorMessage = 'The Income Calculator service is temporarily unavailable or an unexpected error has occurred. Please resubmit at a later time. If this issue persists, contact Income_Calculator@fanniemae.com for assistance.'
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
